.recordings-content {
  padding-top: 24px;
  padding-bottom: 24px;
  height: 100%;
  overflow: auto;
}

.recordings-header-text {
  margin-left: 12px;
  margin-right: 12px;
}

.recordings-started {
  margin-top: 24px;
}

.recordings-map {
  width: 100%;
  max-width: 400px;
}

.recordings-media {
  max-width: 95%;
  max-height: 800px;
}
