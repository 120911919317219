.remote-content {
  padding: 12px 12px 156px;
  height: 100%;
  overflow: auto;
}

.remote-card {
  border: 1px solid #333;
  border-radius: 20px;
  text-align: left;
  padding: 20px;
  margin-bottom: 12px;
}

.remote-card-button-wrapper {
  text-align: center;
  margin-top: 10px;
}

.remote-log {
  margin: 0;
  font-size: 0.9rem;
}

.remote-fixed-buttons-wrapper {
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.remote-button-primary {
  transform: scale(
    1.2
  ); /* TODO(this breaks icon and paddings): --mdc-typography-button-font-size: 24px;*/
  --mdc-theme-primary: #b91b18;
  --mdc-theme-on-primary: #ffffff;
  --mdc-typography-button-text-transform: none;
}

.remote-button-fab {
  transform: scale(
    1.3
  ); /* TODO(this breaks icon and paddings): --mdc-typography-button-font-size: 24px;*/
  --mdc-theme-primary: #b91b18;
  --mdc-theme-on-primary: #ffffff;
  --mdc-typography-button-text-transform: none;
}
